<template>
  <div>
    <van-field label="二维码" right-icon="scan" placeholder="扫描二维码获取" />
    <a
      href="javascript:window.open('//996315.com/api/scan/?redirect_uri=' + encodeURIComponent(http://192.168.3.108:8080/#/dayao/tiaoxingma), '_self');"
      ><button type="button">扫一扫</button>
    </a>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      cameraId: 0, //相机id
      isScan: true,
      
    //   href="https://www.996315.com/api/scan/?redirect_uri=http://192.168.3.108:8080/#/dayao/tiaoxingma"
    };
  },
  mounted() {
    this.init();
  },
  // 页面销毁周期关闭相机
  beforeDestroy() {
    this.stop();
  },
  created() {
    if (location.href.indexOf("qrresult=") > -1)
      alert(decodeURIComponent(location.href.split("qrresult=")[1]));
  },
  methods: {
    onScan() {
      const that = this;
      wx.ready(function () {
        wx.checkJsApi({
          // 需要使用的JS接口列表，在这里只需要用到scanQRCode
          jsApiList: ["scanQRCode"],
          success: function (res1) {
            if (res1.checkResult.scanQRCode) {
              // 当scanQRCode可使用时
              wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res2) {
                  let result = res2.resultStr;
                  console.log(res2.resultStr, "扫描的结果~");
                  window.location.href = result;
                  // 也可以对扫描结果处理过之后再使用
                  // 比如可以这样使用：
                  // window.location.href = result.split('?')[0] + '/detail?' + result.split('?')[1]
                },
                error: function (response) {
                  that.$toast(response);
                },
              });
            }
          },
        });
      });
    },
    init() {
      util.addJs(
        "https://blog.minhazav.dev/assets/research/html5qrcode/html5-qrcode.min.js"
      );
    },
    getCameras: function () {
      var a = "//996315.com/api/scan/";
      a.setAttribute("href", "//996315.com/api/scan/");
      document.body.appendChild(a);
      a.click();
    },
    // 开始扫描
    // getCameras() {
    //    Html5Qrcode.getCameras().then(devices => {
    //      if(devices){
    //        if (devices.length==1) {
    //          this.cameraId = devices[0].id;
    //        }else{
    //          this.cameraId = devices[1].id;
    //        }
    //        this.start();
    //      }
    //    }).catch(err => {
    //        console.log(err);
    //      this.$notify({
    //        type: 'warning',
    //        message: '调用摄像头失败 : ' + err
    //      });
    //    });
    //  },
    // 启动相机
    start() {
      this.isScan = false;
      this.html5QrCode = new Html5Qrcode("qr-reader");
      this.html5QrCode
        .start(
          this.cameraId,
          {
            fps: 10,
            qrbox: { width: 250, height: 250 },
          },
          (qrCodeMessage) => {
            if (qrCodeMessage) {
              this.stop();
            }
          }
        )
        .catch((err) => {
          console.log(`Unable to start scanning, error: ${err}`);
        });
    },
    // 关闭相机
    stop() {
      this.isScan = true;
      this.html5QrCode
        .stop()
        .then((ignore) => {
          console.log("QR Code scanning stopped.");
        })
        .catch((err) => {
          console.log("Unable to stop scanning.");
        });
    },
  },
};
</script>
<style scoped>
.scan {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}
</style>